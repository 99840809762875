<template>
  <div class="home pa-16">

    
    HOME - Aplicatie in curs de dezvoltare
  </div>
</template>

<script>
// @ is an alias to /src
import TheForm from '@/components/TheForm.vue'

export default {
  name: 'HomeView',
  props: {
      form: {
          type: Object,
          required: true
      }
  },
  components: {
    TheForm
  },
  data: () => ({
            drawer: null,
            unfoldedObject: [],
            totalFaraTva: 0,
            totalTva: 0,
            total: 0,
            text: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
            pasted: false,
            firstValue: '',
            isSaved: null,
            nrDeviz: 0,
            numeDeviz: null
        }),
        created () {
            // this.getForm(1);
        },
        methods: {
            cellContent (td, index) {
                const result = (this.conditionsPDF(td.conditions) ? false : '0') || (td.index ? index + 1 : false ) || this.getDataforPDF(td.field, td.get) || this.i18n(td, 'value') || this.calc(td.calc) || 0;

                return typeof result == 'number' ? result.toFixed(td.decimals || 0) : result;
            },
            pasteRow (e) {
                if (e.target.type === "text") {
                    var data = e.clipboardData.getData('Text');

                    data = data.split('\t');

                    for (var i = 0; i < this.text.length; i++) {
                        this.text[i] = data[i]
                    }

                    [].forEach.call(document.querySelectorAll(".text-cell"), (node, index) => {
                        if (index === 0) {
                            this.firstValue = data[0];
                        }
                        this.pasted = true;
                        this.text[index] = data[index];
                    });
                }
            },
            correctPaste () {
                if (this.pasted) {
                    this.pasted = false;
                    this.text[0] = this.firstValue;
                }
                this.mappingFields();
            },
            mappingFields () {
                this.findObjectById('field-vreau-chirie-pe-iarba', this.form).value = [];
                this.findObjectById('field-suprafata-pe-iarba', this.form).value = 0;

                if (this.text[0]) {
                    this.findObjectById('field-denumire-companie', this.form).value = this.text[0];
                }

                if (this.text[5] > 8 && this.text[5] < 25) {
                    this.findObjectById('field-vreau-chirie-pe-iarba', this.form).value = ["stand-in-aer-liber"];
                    this.findObjectById('field-suprafata-pe-iarba', this.form).value = this.text[5];
                }

                if (this.text[6] > 24 && this.text[6] < 100) {
                    this.findObjectById('field-vreau-chirie-pe-iarba', this.form).value = ["stand-in-aer-liber"];
                    this.findObjectById('field-suprafata-pe-iarba', this.form).value = this.text[6];
                }

                if (this.text[7] > 100) {
                    this.findObjectById('field-vreau-chirie-pe-iarba', this.form).value = ["stand-in-aer-liber"];
                    this.findObjectById('field-suprafata-pe-iarba', this.form).value = this.text[7];
                }

                if (this.text[8] > 0) {
                    this.findObjectById('field-stand-aer-liber', this.form).value = "construieste-cu-organizatorul";
                    this.findObjectById('field-tip-cort-organizator', this.form).value = "cort-standard-tip-pagoda";
                    this.findObjectById('field-suprafata-aer-liber', this.form).value = Number(this.text[8]);
                } else {
                    this.findObjectById('field-suprafata-aer-liber', this.form).value = Number(this.text[8]);
                }

                if (this.text[9] > 0) {
                    this.findObjectById('field-stand-aer-liber', this.form).value = "construieste-cu-organizatorul";
                    this.findObjectById('field-tip-cort-organizator', this.form).value = "cort-standard-tip-clasic";
                    this.findObjectById('field-suprafata-aer-liber-tip-clasic', this.form).value = Number(this.text[9]);
                } else {
                    this.findObjectById('field-suprafata-aer-liber-tip-clasic', this.form).value = Number(this.text[9]);
                }

                if (this.text[10] > 0) {
                    this.findObjectById('field-stand-aer-liber', this.form).value = "construieste-in-regie-proprie";
                }

                if (this.text[10] > 0) {
                    // console.log('excel-10', this.text[10])
                    this.findObjectById('field-stand-aer-liber', this.form).value = "nu-construieste-cort-container";
                }

                if (this.text[14] > 0) {
                    
                    const fieldStand = this.findObjectById('field-stand', this.form);
                    fieldStand.value = "stand-amenajat";

                    const fieldLaturiLibere = this.findObjectById('field-laturi-libere', this.form);

                    if (this.conditions(fieldLaturiLibere, fieldLaturiLibere.conditions) ) {
                        fieldLaturiLibere.value = "1-latura-libera";
                    }
                    
                    this.findObjectById('field-suprafata', this.form).value = this.text[14];

                    const fieldTarifUnitar = this.findObjectById('field-tarif-unitar', this.form);

                    if (fieldTarifUnitar.inheritValue) {
                        this.inheritValue(fieldTarifUnitar);
                    }

                    const fieldTotal = this.findObjectById('field-total', this.form);

                    if (fieldTotal.calc) {
                        fieldTotal.value = this.calcFromExcel(fieldTotal, fieldTotal.calc);
                    }
                }

                if (this.text[15] > 0) {
                    this.findObjectById('field-stand', this.form).value = "stand-amenajat";
                    this.findObjectById('field-laturi-libere', this.form).value = "2-3-laturi-libere";
                    this.findObjectById('field-suprafata', this.form).value = this.text[15];
                }

                if (this.text[16] > 0) {
                    this.findObjectById('field-stand', this.form).value = "stand-neamenajat";
                    this.findObjectById('field-laturi-libere', this.form).value = "1-latura-libera";
                    this.findObjectById('field-suprafata', this.form).value = this.text[16];
                }

                if (this.text[17] > 0) {
                    this.findObjectById('field-stand', this.form).value = "stand-neamenajat";
                    this.findObjectById('field-laturi-libere', this.form).value = "2-3-laturi-libere";
                    this.findObjectById('field-suprafata', this.form).value = this.text[17];
                }

                if (this.text[18] > 0) {
                    this.findObjectById('field-stand', this.form).value = "stand-colectiv";
                    this.findObjectById('field-suprafata', this.form).value = this.text[18];
                }

                if (this.text[19] > 0) {
                    this.findObjectById('field-numar-bucati-pazie', this.form).value = this.text[19];

                    const fieldTotalPazie = this.findObjectById('field-total-pazie', this.form);

                    if (fieldTotalPazie.calc) {
                        fieldTotalPazie.value = this.calcFromExcel(fieldTotalPazie, fieldTotalPazie.calc);
                    }
                    
                }
            },
            getForm (id) {
                const url = 'https://dlg.dev-factory.ro/php/get-form-json.php';
                axios.get(url, { params: {id} }).then(response => {
                    this.form = response.data;
                });
            },
            saveForm () {
                const numeCompanie = this.findObjectById('field-denumire-companie', this.form).value;
                axios.post('https://dlg.dev-factory.ro/php/save-form-json.php', { json: this.form, numeCompanie }).then(response => {
                    // console.log(response.data);
                    // this.exportToPDF();
                    this.isSaved = true;
                });
            },
            getDataforPDF (id, key) {
                return this.findObjectById(id, this.form.sections) ? this.i18n(this.findObjectById(id, this.form.sections), key) : false;
            },
            findObjectById (id, object) {
                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        if (object[key] && typeof object[key] === "object") {
                            const result = this.findObjectById(id, object[key]);
                            if (result) {
                                return result;
                            }
                        }
                        if (object[key] && object[key].id === id) {
                            return object[key];
                        } 
                    }
                }
            },
            exportToPDF () {
                const url = 'https://dlg.dev-factory.ro/php/get-devize.php';
                axios.get(url).then(response => {
                    // console.log(response.data);
                    const nrDev = ((response.data + 1) / 100).toFixed(2).toString();
                    this.nrDeviz = nrDev.replace('.', '');

                    const numeCompanie = this.findObjectById('field-denumire-companie', this.form).value;
                    



                    const doc = new jsPDF({
                        orientation: "portrait",
                        unit: "px",
                        format: [874, 1240],
                        hotfixes: ["px_scaling"]
                    })

                    // const namePDF = this.namePDF;

                    doc.html(document.getElementById('element-to-convert'), {
                        callback: doc => {
                            this.numeDeviz = this.namePDF(this.nrDeviz);

                            const urlSavePDF = 'https://dlg.dev-factory.ro/php/save-deviz-pdf.php';
                            const pdfData = doc.output('blob');
                            const formData = new FormData();
                            formData.append('pdf', pdfData, this.numeDeviz);
                            console.log(formData)

                            axios.post(urlSavePDF, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(responsex => {
        console.log("PDF saved successfully.", responsex);
      })
      .catch(error => {
        console.error("Error saving PDF: " + error);
      });

      const urlSave = 'https://dlg.dev-factory.ro/php/save-deviz.php';
                    axios.post(urlSave, { numeCompanie, numeDev: this.numeDeviz }).then(response2 => {
                        console.log(response2.data);
                        // this.exportToPDF();
                        // this.isSaved = true;
                    });


                            doc.save(this.numeDeviz);
                        },
                        x: 0,
                        y: 0,
                    });
                });

                
            },
            namePDF (deviz) {
                const numeComp = this.findObjectById("field-denumire-companie", this.form).value;
                const devizNr = `AG2023${deviz}`;
                return `${numeComp} Deviz_AG2023${deviz}.pdf`;
            },
            changeToRomanian () {
                this.form.data.language = "en"
            },
            changeToEnglish () {
                this.form.data.language = "ro"
            },
            i18n (obj, key) {
                if (!obj) return;
                if (obj.i18n) {
                    if (obj.i18n[this.form.data.language]) {
                        if (obj.i18n[this.form.data.language][key]) {
                            return obj.i18n[this.form.data.language][key];
                        }
                    }
                }
                return obj[key];
            },
            conditionsPDF (conditions) {
                if (!conditions) {return true;}
                const logics = conditions || [];
                if (logics.length < 0) {return true;}

                const operator = {
                    'isGreater': ( a, b ) => a > b,
                    'isEqual': ( a, b ) => a === b,
                    'isEqualToCheckbox': ( a, b ) => JSON.stringify(a) === JSON.stringify(b),
                    'isLang': ( a, b ) => this.form.data.language == a
                };

                const output = [];

                for (const logic in logics) {
                    const arr = [];
                    for (const logicAnd in logics[logic]) {
                        const a = logics[logic][logicAnd].field ? this.findObjectById(logics[logic][logicAnd].field, this.form).value : "";
                        const b = logics[logic][logicAnd].value;
                        arr.push(operator[ logics[logic][logicAnd].operator ](b, a));
                    }
                    output.push(arr.every(a => a));
                }
                return output.some(a => a);
            },
            hasValue (table, tbody) {
                if ( table.id !== 'elem-table-desfasurator') return true;

                const isArr = [];
                for (const td in tbody) {

                    if (tbody.length < 5) {
                         return true;
                    }
                    if (td == 1) {
                        // console.log(this.cellContent(tbody[td], td))
                        isArr.push(this.cellContent(tbody[td], td) > 0)
                    }
                }
                const output = isArr.some(a => a);
                
                return output;
            },
            conditions (field, conditions) {
                if (!conditions) {return true;}
                const logics = conditions.logics || [];
                if (logics.length < 0) {return true;}

                const operator = {
                    'isEqual': ( a, b ) => a === b,
                    'isGreater': ( a, b ) => a > b,
                    'isLower': ( a, b ) => a < b,
                    'isEqualToCheckbox': function( a, b ) {return JSON.stringify(a) === JSON.stringify(b)},
                    'isLang': ( a, b ) => this.form.data.language == a
                };

                const output = [];

                for (const logic in logics) {
                    const arr = [];
                    for (const logicAnd in logics[logic]) {
                        const a = logics[logic][logicAnd].field ? this.findObjectById(logics[logic][logicAnd].field, this.form).value : "";
                        const b = logics[logic][logicAnd].value;
                        arr.push(operator[ logics[logic][logicAnd].operator ](a, b));
                    }
                    output.push(arr.every(a => a));
                }

                field.active = output.some(a => a);
                return output.some(a => a);
            },
            inheritValue (field) {
                let arr = [];
                for (const key in field.inheritValue) {
                    arr = [];
                    for (const k in field.inheritValue[key].if) {
                        arr.push(field.inheritValue[key].if[k].value === this.findObjectById(field.inheritValue[key].if[k].id, this.form ).value)
                    }
                    const is = arr.every(a => a);
                    if (is) {
                        field.value = field.inheritValue[key].value;
                        return field.value;
                    }
                }
            },
            downloadJson () {
                let text = JSON.stringify(this.form);
                let filename = `${this.i18n(this.form.data, 'title')} - ${this.i18n(this.form.data,'eventName')}.json`;
                let element = document.createElement('a');
                element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            },
            formatDate () {
                let d     = new Date();
                let month = '' + (d.getMonth() + 1);
                let day   = '' + d.getDate();
                let year  = d.getFullYear();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;

                return [day, month, year].join('.');
            },
            calc (calc) {
                const arr = [];
                for (const key in calc) {
                    if (calc[key] === '*') {
                        arr.push('*');
                    } else if (calc[key] === '+') {
                        arr.push('+');
                    } else if (calc[key] === '-') {
                        arr.push('-');
                    } else if (calc[key] === '(') {
                        arr.push('(');
                    } else if (calc[key] === ')') {
                        arr.push(')');
                    } else if (calc[key] === 'if') {
                        arr.push('if');
                    } else if (calc[key] === 'else') {
                        arr.push('else');
                    } else if (calc[key] === '{') {
                        arr.push('{');
                    } else if (calc[key] === '}') {
                        arr.push('}');
                    } else if (calc[key] === '>') {
                        arr.push('>');
                    } else if (calc[key] === '<') {
                        arr.push('<');
                    } else if (calc[key] === '&&') {
                        arr.push('&&');
                    } else if (calc[key] === 'isLangRo') {
                        arr.push(this.form.data.language === 'ro');
                    } else {
                        if(typeof calc[key] == 'number') {
                            arr.push(calc[key]);
                        } else {
                            const op = this.findObjectById(calc[key], this.form );

                            if (typeof op !== 'undefined') {
                                if (op.active) {
                                    // console.log(Number(op.value))
                                    arr.push(Number(op.value));
                                } else {
                                    if (arr[arr.length -1] === "*" || arr[arr.length -1] === "/") {
                                        arr.push(1);
                                    } else {
                                        arr.push(0);
                                    }
                                }
                            } else {
                                console.error("calc", calc[key]);
                            }
                        }
                    }
                }

                return eval( arr.join(" "));
            },
            calcFromExcel (field, calc) {
                const arr = [];
                for (const key in calc) {
                    if (calc[key] === '*') {
                        arr.push('*');
                    } else if (calc[key] === '+') {
                        arr.push('+');
                    } else if (calc[key] === '-') {
                        arr.push('-');
                    } else if (calc[key] === '(') {
                        arr.push('(');
                    } else if (calc[key] === ')') {
                        arr.push(')');
                    } else if (calc[key] === 'if') {
                        arr.push('if');
                    } else if (calc[key] === 'else') {
                        arr.push('else');
                    } else if (calc[key] === '{') {
                        arr.push('{');
                    } else if (calc[key] === '}') {
                        arr.push('}');
                    } else if (calc[key] === '>') {
                        arr.push('>');
                    } else if (calc[key] === '&&') {
                        arr.push('&&');
                    } else if (calc[key] === '<') {
                        arr.push('<');
                    } else if (calc[key] === 'isLangRo') {
                        arr.push(this.form.data.language === 'ro');
                    } else {
                        if(typeof calc[key] == 'number') {
                            arr.push(calc[key]);
                        } else {
                            const op = this.findObjectById(calc[key], this.form );
                            if (typeof op !== 'undefined') {
                                if (op.active) {
                                    arr.push(Number(op.value));
                                } else {
                                    if (arr[arr.length -1] === "*" || arr[arr.length -1] === "/") {
                                        arr.push(1);
                                    } else {
                                        arr.push(0);
                                    }
                                }
                            } else {
                                console.error("calc", calc[key]);
                            }
                        }
                    }
                }
                // field.value = eval( arr.join(" ") );
                return eval( arr.join(" "));
            },
        }

    }
</script>
